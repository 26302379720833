import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/vercel/workpath0/packages/swarm-docs/src/components/layout-mdx.js";
import Icons from '../../components/Icons';
import * as SolidIcons from '@meetup/swarm-icons/lib/components/solid';
import * as LineIcons from '@meetup/swarm-icons/lib/components/line';
import * as LargeIcons from '@meetup/swarm-icons/lib/components/large';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Icons`}</h1>
    <hr></hr>
    <br />
    <h3>{`Line Icon Family`}</h3>
    <Icons iconFamily='line' icons={LineIcons} mdxType="Icons" />
    <br />
    <br />
    <h3>{`Solid Icon Family`}</h3>
    <Icons iconFamily='solid' icons={SolidIcons} mdxType="Icons" />
    <br />
    <br />
    <h3>{`Large Icon Family`}</h3>
    <Icons iconFamily='large' icons={LargeIcons} mdxType="Icons" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      